'use strict';

const {
	section, h1, h2, h3, hr, header, i, ul, li, p,
	table, thead, tbody, tr, td, th
} = require('iblokz-snabbdom-helpers');

const marked = require('marked');

const rightColumn = require('../right-column');

module.exports = ({state, actions}) => [
	section('.content', [
		section('.post', [
			h1('Алманах “Родолюбец“')
		]),
		section('.post', [
			p({props: {innerHTML: marked(`
Като печатно издание на дружество “Родолюбец” всяка четна година излиза алманахът “Родолюбец”, една истинска “христоматия по родолюбие”.

Като сборник от полезни, тематични четива, Алманахът има утвърдени 8 дяла, които се поддържат във всеки брой, както следва:
- **Дял I. ДЕ Е БЪЛГАРИЯ?** - географски очерци за България или за нейни области и очерци за обекти в тях.
- **Дял II. БЪЛГАРИЯ ПРЕЗ ВЕКОВЕТЕ** - Исторически очерци за България.
- **Дял III. ЮБИЛЕЙНИ ГОДИШНИНИ** - Отразяват се значителни събития от българската история, явяващи се юбилейни към годината на издаването на Алманаха.
- **Дял IV. БЪЛГАРСКИ ПАНТЕОН** - Раздел, в който са отбелязани значими личности от българската политическа и културна история.
- **Дял V. СЪКРОВИЩНИЦА НА НАРОДНИЯ ДУХ** - Статии и очерци върху българския фолклор и образци от народни песни и приказки.
- **Дял VI. С БЪЛГАРИЯ В СЪРЦЕТО** - Най-важният и най-обемист дял от сборника, изпълнен с изповеди, спомени, статии и очерци на бесарабски и за бесарабски българи, очерци за българите-гагаузи в Бесарабия и в България и за българите живеещи вън от границите на България.
- **Дял VII. ТВОРЦИ ОТ БЕСАРАБИЯ** - кратки биографични бележки и творби на бесарабски поети и творци
- **Дял VIII. РОДОЛЮБЕЦ ЗА СЕБЕ СИ** - раздел, в който дружеството отразява някои от своите дейности, публикува нормативни документи, свързани с обучението на студентите от българската диаспора извън границите на днешна България, специалисти и обнародва списъци на приети у нас студенти, на завършилите в България специалисти и на изпратените в Бесарабия учители.
			`)}})
		])]
	),
	rightColumn({state, actions})
];
