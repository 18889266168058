'use strict';

const {
	section, h1, h2, h3, hr, header, i, ul, li, p,
	table, thead, tbody, tr, td, th, span, a, img
} = require('iblokz-snabbdom-helpers');

const rightColumn = require('../right-column');

const marked = require('marked');
const moment = require('moment');

const pinnedFirst = (articles = []) => articles
	.reduce((list, a) => a.pinned ? [a].concat(list) : list.concat(a), []);

module.exports = ({state, actions}) => [
	
	section('.content', [
		/*
		a('[href="https://www.facebook.com/events/1781298892137645/"][target="_blank"]', [
			img('.article[src="/img/mh100.png"][style="padding: 0"]')
		])
		*/
	].concat(
			section('.main-links', [
				ul([
					// li([a('[href="https://goo.gl/maps/nuw3q3d9CuK2"][target="_blank"]', [
					// 	i('.fa.fa-map-marker'),
					// 	'бул. „Евлоги Георгиев“ 169, ет. II-ри'
					// ])]),
					li([a('[href="https://fb.com/groups/rodolubets"][target="_blank"]', [
						i('.fa.fa-facebook-official'),
						' Facebook Група'
					])]),
					li([a('[href="https://www.youtube.com/channel/UC29vwswzZgc4QjO0NDJKzdQ"][target="_blank"]', [
						i('.fa.fa-youtube'),
						' Youtube Канал'
					])]),
					li([a('[href="mailto:rodolubets@abv.bg"]', [
						i('.fa.fa-envelope-o'),
						' Ел. Поща'
					])])
				])
			]),
			pinnedFirst(state.articles.list).map(article =>
				section('.post', [
					h1([a(`[href="#/articles/${article._id}"]`, article.title)]),
					p('.meta', [
						span('.left', article.categories && article.categories.join(', ') || ''),
						span('.right', [
							(article.publishedIn || article.createdAt) && 'Публикувана ' || '',
							article.publishedIn && `в ${article.publishedIn} ` || '',
							article.createdAt && `на ${moment(article.createdAt).format('DD MMMM Y')} ` || '',
							article.author && `Автор: ${article.author}` || ''
						])
					]),
					p('.body', {props: {innerHTML: marked(article.text)}})
				]))
	)),
	rightColumn({state, actions})
];
