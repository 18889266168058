'use strict';

const {
	section, h1, h2, h3, hr, header, i, ul, li, p, button, div, span,
	table, thead, tbody, tr, td, th, a, form, label, input, textarea,
	select, option
} = require('iblokz-snabbdom-helpers');

const $ = require('rx').Observable;
const {obj} = require('iblokz-data');

const md = require('../../../../util/md');
const moment = require('moment');

const formToData = form => Array.from(form.elements)
	.filter(el => el.name !== undefined)
	.reduce((o, el) => ((o[el.name] = el.value), o), {});

const roles = {
	user: 'Потребител',
	editor: 'Редактор',
	admin: 'Администратор'
};

module.exports = ({state, actions}) =>
(state.users.doc._id === state.router.pageId || state.router.pageId === 'new')
	? div('.edit', [
		form({
			on: {
				submit: ev => {
					ev.preventDefault();
					let data = formToData(ev.target);
					if (data.password !== '' && data.password === data.passwordConfirm) {
						data = obj.filter(data, key => !key.match('passwordConfirm'));
					} else {
						data = obj.filter(data, key => !key.match('password'));
					}
					console.log(data, state.auth);
					actions.users.save(data, state.auth.token);
					actions.router.go('admin.users');
					return false;
				}
			}
		}, [
			state.users.doc._id && input('[type="hidden"][name="_id"]', {props: {value: state.users.doc._id || ''}}) || '',
			div([
				label('Име'),
				input('[type="text"][name="fullName"]', {props: {value: state.users.doc.fullName || ''}})
			]),
			div([
				label('Ел. Адрес'),
				input('[type="text"][name="email"]', {props: {value: state.users.doc.email || ''}})
			]),
			div([
				label('Роля'),
				select('[name="role"]', {props: {value: state.users.doc.role || ''}}, Object.keys(roles).map(role =>
					option(`[value="${role}"]`, {
						attrs: {
							selected: role === state.users.doc.role
						}
					}, roles[role])
				))
			]),
			div([
				label('Парола'),
				input('[type="password"][name="password"]')
			]),
			div([
				label('Потвърди Парола'),
				input('[type="password"][name="passwordConfirm"]')
			]),
			div([
				button('[type="submit"]', 'Save')
			])
		])
	])
	: '';
