'use strict';

const Rx = require('rx');
const $ = Rx.Observable;
const {Subject} = Rx;
const path = require('path');

const {obj} = require('iblokz-data');
const request = require('superagent');
const app = require('../util/app');
const auth = require('./auth');

const port = ''; // process.env.PORT && process.env.PORT !== '80' ? `:${process.env.PORT}` : '';
const url = `//${location.host || process.env.SERVER_URL || 'localhost'}${port}`;

const initial = {
	query: {
		start: 0,
		limit: 100,
		search: ''
	},
	list: [],
	doc: {},
	focused: false,
	dirty: true
};

const reservedPageIds = ['new', 'configure'];
// const anyOf = (list, filter)

let handleUnauthorized = err => (
	console.log(err),
	err.status === 401
		? auth.actions.forceLogout()
		: state => state
);

// ns - namespace
const list = ns => (query = {}, ctrl = '', token) => request.get(`${url}/api/${path.join(ns, ctrl)}`)
	.set(token && {'x-access-token': token} || {})
	.query(query)
	.then(res => res.body)
	.then(data => state => obj.patch(state, ns,
		Object.assign({}, data, {dirty: false})
	))
	.catch(handleUnauthorized);

const query = ns => keyValuePair => state => obj.patch(state, ns, {
	query: Object.assign({}, obj.sub(state, [].concat(ns, 'query')), keyValuePair),
	dirty: true
});

const create = ns => (doc, token, cb = () => {}) => request.post(`${url}/api/${ns}`)
	.set(token && {'x-access-token': token} || {})
	.send(doc)
	.then(() => (
		cb(),
		state => obj.patch(state, ns, {dirty: true, doc: {}})
	))
	.catch(handleUnauthorized);

const read = ns => (id, token) => request.get(`${url}/api/${ns}/${id}`)
	.set(token && {'x-access-token': token} || {})
	.then(res => res.body)
	.then(doc => state => obj.patch(state, ns, {doc}))
	.catch(handleUnauthorized);

const update = ns => (id, doc, token, cb = () => {}) => request.put(`${url}/api/${ns}/${id}`)
	.set(token && {'x-access-token': token} || {})
	.send(doc)
	.then(() => (
		cb(),
		state => obj.patch(state, ns, {dirty: true, doc: {}})
	))
	.catch(handleUnauthorized);

const save = ns => (doc, token, cb = () => {}) => doc._id
	? update(ns)(doc._id, doc, token, cb)
	: create(ns)(doc, token, cb);

const _delete = ns => (id, token) => request.delete(`${url}/api/${ns}/${id}`)
	.set(token && {'x-access-token': token} || {})
	.then(() => state => obj.patch(state, ns, {dirty: true, doc: {}}))
	.catch(handleUnauthorized);

const reset = ns => () => state => obj.patch(state, ns, {doc: {}});

const filter = ns => query => state => obj.patch(state, [ns, 'query'], query);

const actions = {
	initial,
	list,
	query,
	create,
	update,
	save,
	delete: _delete,
	read,
	reset,
	filter
};

const applyNs = (o, ns) => obj.map(o, (k, v) => (v instanceof Function) ? v(ns) : v);

const attach = (o, ns) => app.attach(o, ns, applyNs(actions, ns));

let subs = [];
let unhook = () => subs.forEach(sub => sub.dispose());

const hook = ({state$, actions}) => ns => {
	// dirty || logged in
	subs.push(
		state$
			.distinctUntilChanged(state => [state[ns].dirty, state.auth.user])
			.filter(state => state[ns].dirty || state.auth.user)
			.subscribe(state => actions[ns].list(
				state[ns].query,
				ns === 'content' && state.router.admin ? state.router.pageId : '',
				state.auth.token))
	);
	// on edit load doc
	subs.push(
		state$
			.distinctUntilChanged(state => state.router)
			.filter(state => state.router.pageId !== null || state.router.page.match(/content/))
			.subscribe(state =>
				state.router.page.match(ns) && (state.router.pageId === 'new'
					? actions[ns].reset()
					: ns === 'content'
						? actions[ns].list(actions[ns].query, state.router.pageId, state.auth.token)
						: actions[ns].read(state.router.pageId, state.auth.token))
			)
	);
};

module.exports = {
	actions,
	applyNs,
	attach,
	hook,
	unhook: () => unhook()
};
