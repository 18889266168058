'use strict';

// lib
const Rx = require('rx');
const $ = Rx.Observable;

require('moment/locale/bg');

// util
const vdom = require('iblokz-snabbdom-helpers');
const {obj} = require('iblokz-data');

// config
const config = {
	routes: [
		'admin/:page/:pageId',
		':page/:pageId'
	]
};
// app
const app = require('./util/app');
let actions = app.adapt(require('./actions'));
let ui = require('./ui');
let actions$;
const state$ = new Rx.BehaviorSubject();

// services
// auth
let auth = require('./services/auth');
actions = app.attach(actions, 'auth', auth.actions);
// router
let router = require('./services/router');
actions = app.attach(actions, 'router', router.actions);
// resource
let resource = require('./services/resource.js');
let resources = ['articles', 'events', 'pages', 'content', 'users']; // Object.keys(rest.config.routes.api)
	// .filter(route => route !== '_meta'); // ['items', 'categories', 'locations'];
actions = resources.reduce(resource.attach, actions);

// hot reloading
if (module.hot) {
	// actions
	actions$ = $.fromEventPattern(
    h => module.hot.accept("./actions", h)
	).flatMap(() => {
		actions = app.adapt(require('./actions'));
		return actions.stream.startWith(state => state);
	}).merge(actions.stream);
	// ui
	module.hot.accept("./ui", function() {
		ui = require('./ui');
		actions.stream.onNext(state => state);
	});
	// services
	// auth
	module.hot.accept("./services/auth.js", function() {
		auth.unhook();
		auth = require('./services/auth.js');
		actions = app.attach(actions, 'auth', auth.actions);
		auth.hook({state$, actions});
		actions.ping();
	});
	// router
	module.hot.accept("./services/router.js", function() {
		router.unhook();
		router = require('./services/router.js');
		actions = app.attach(actions, 'router', router.actions);
		router.hook({state$, actions});
		actions.ping();
	});
	// resource
	module.hot.accept("./services/resource.js", function() {
		resource.unhook();
		resource = require('./services/resource.js');
		actions = resources.reduce(resource.attach, actions);
		resources.forEach(resource.hook({state$, actions}));
		actions.ping();
	});
} else {
	actions$ = actions.stream;
}

// actions -> state
actions$
	.startWith(() => actions.initial)
	.scan((state, change) => change(state), {})
	.map(state => (console.log(state), state))
	.subscribe(state => state$.onNext(state));

// state change hooks
auth.hook({state$, actions});
router.hook({state$, actions});
resources.forEach(resource.hook({state$, actions}));

// console.log({actions});

// trigger read action on pageId param
// state$
// 	.distinctUntilChanged(state => state.router)
// 	.filter(state => state.router.pageId !== null || state.router.page.match(/assets/))
// 	.subscribe(state => resList.forEach(res =>
// 		state.router.page.match(res) && (state.router.pageId === 'new'
// 			? actions[res].reset()
// 			: res === 'assets'
// 				? actions[res].list(actions[res].query, state.router.pageId)
// 				: actions[res].read(state.router.pageId))
// 	));

// map state to ui
const ui$ = state$.map(state => ui({state, actions}));

// patch stream to dom
vdom.patchStream(ui$, '#ui');

window.actions = actions;

// livereload impl.
if (module.hot) {
	document.write(`<script src="http://${(location.host || 'localhost').split(':')[0]}` +
	`:35729/livereload.js?snipver=1"></script>`);
}
