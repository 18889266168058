'use strict';

const Rx = require('rx');
const $ = Rx.Observable;

const {obj} = require('iblokz-data');

const initial = {
	signIn: false,
	editor: {
		wysiwyg: true,
		pos: {
			start: {row: 0, col: 0},
			end: {row: 0, col: 1}
		}
	},
	admin: {
		content: {
			listType: 1
		}
	}
};

const set = (path, val) => state => obj.patch(state, path, val);
const toggle = path => state => obj.patch(state, path, !obj.sub(state, path));
const ping = () => state => state;

const editArticle = (text, sel) => state => obj.patch(
	obj.patch(state, ['articles', 'doc'], {text}),
	'editor', {sel}
);

const edit = ({res, field, value, pos}) => state => obj.patch(
	obj.patch(state, [res, 'doc', field], value),
	'editor', {pos}
);

module.exports = {
	initial,
	set,
	toggle,
	ping,
	editArticle,
	edit
};
