'use strict';

const {
	section, h1, h2, h3, hr, header, i, ul, li, p, a,
	table, thead, tbody, tr, td, th, br, h, fieldset, legend
} = require('iblokz-snabbdom-helpers');

const moment = require('moment');
require('moment/locale/bg');

const calendar = require('./calendar');
const video = require('../comp/video');

const formatDate = ev => (moment(ev.startDateTime).format('DD') !== moment(ev.endDateTime).format('DD'))
	? moment(ev.startDateTime).format('DD') + '-' + moment(ev.endDateTime).format('DD.MM')
	: moment(ev.startDateTime).format('DD.MM HH:mm');

const getPreviousEvents = (list = []) => list.filter(ev => new Date(ev.endDateTime) < new Date())
	.sort((a, b) => new Date(a.startDateTime) < new Date(b.startDateTime) ? 1 : -1);

const groupByYear = list => list.reduce((byYear, ev) => ({
	...byYear,
	...([moment(ev.startDateTime).format('YYYY')].map(year => ({
		[year]: [].concat(byYear[year] || [], ev)
	})).pop())
}), {});

module.exports = ({state, actions}) => section('.right-column', [].concat(
	section([
		ul([
			// li([a('[href="https://goo.gl/maps/nuw3q3d9CuK2"][target="_blank"]', [
			// 	i('.fa.fa-map-marker'),
			// 	'бул. „Евлоги Георгиев“ 169, ет. II-ри'
			// ])]),
			li([a('[href="https://fb.com/groups/rodolubets"][target="_blank"]', [
				i('.fa.fa-facebook-official'),
				'Facebook Група на д-во Родолюбец'
			])]),
			li([a('[href="https://www.youtube.com/channel/UC29vwswzZgc4QjO0NDJKzdQ"][target="_blank"]', [
				i('.fa.fa-youtube'),
				'Youtube Канал на Дружеството'
			])]),
			li([a('[href="mailto:rodolubets@abv.bg"]', [
				i('.fa.fa-envelope-o'),
				'Ел. Поща: rodolubets at abv dot bg'
			])])
		])
	]),
	section([
		h(`svg.decoration`,
			h(`text`, {attrs: {x: 1, y: 24}}, `От Видео Архива ...`)
		),
		video({src: `https://www.youtube.com/watch?v=3cbs_fS8XgM`}),
		h2('Концерт по случай Деня на българската просвета и култура - 22.05.1995 г.'),
		ul([
			li([a(
				{
					attrs: {
						href: 'https://www.youtube.com/watch?v=4difTYHUWww&list=PLMRsswhM9h_Mg5mqMlH5yGLVhiLwjWrrY', target: '_blank'
					}
				}, [
					i('.fa.fa-youtube'),
					'Вижте още празненства и чествания'
				])])
		])
	]),
	state.events.list && state.events.list.filter(ev => new Date(ev.endDateTime) >= new Date()).length > 0
		? section([
			h2('Предстои:'),
			ul(
				state.events.list.filter(ev => new Date(ev.endDateTime) >= new Date())
					.sort((a, b) => new Date(a.startDateTime) < new Date(b.startDateTime) ? 1 : -1)
					.map(ev =>
						li([a(
							`[target="_blank"]`,
							{attrs: {href: ev.url}},
							formatDate(ev) + ' ' + ev.name
						)])
					)
			)
			/*
			ul([
				li([a(
					'[href="https://www.facebook.com/events/678792822320208"][target="_blank"]',
					'26-28.10 Прояви по случай Деня на бесарабските българи'
				)])
			])
			*/
		]) : [],
	section([].concat(
		h(`svg.decoration`,
			h(`text`, {attrs: {x: 1, y: 24}}, `Минали събития:`)
		),
		// h2('Минали събития:'),
		[getPreviousEvents(state.events.list)].map(groupByYear)
			.map(byYear => (console.log(byYear), byYear))
			.map(byYear => Object.keys(byYear).sort((a, b) => a > b ? -1 : 1)
				.map(year => fieldset([
					legend(year),
					ul(byYear[year].map(ev =>
						li([a(
							`[target="_blank"]`,
							{attrs: {href: ev.url}},
							formatDate(ev) + ' ' + ev.name
						)])
					))
				]))).pop()
	)),
	calendar({state, actions})
));
