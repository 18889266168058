'use strict';

const {
	section, h1, h2, h3, hr, header, i, ul, li, p,
	table, thead, tbody, tr, td, th, a
} = require('iblokz-snabbdom-helpers');

const marked = require('marked');
const {obj} = require('iblokz-data');

const breadcrumb = require('./breadcrumb');

const pages = {
	events: require('./events'),
	articles: require('./articles'),
	content: require('./content'),
	pages: require('./pages'),
	users: require('./users'),
	default: ({state, actions}) => section('.post', [
		p({props: {innerHTML: marked(`
			Добре Дошли!
		`)}})
	])
};

console.log(pages);

const i18n = {
	new: 'Създай',
	edit: 'Редактирай',
	pages: {
		admin: 'Администрация',
		events: 'Събития',
		articles: 'Публикации',
		pages: 'Страници',
		users: 'Потребители',
		content: 'Файлове'
	}
};

module.exports = ({state, actions}) => [
	section('.content', [].concat(
		breadcrumb({state, actions, i18n}),
		obj.switch(state.router.path[1], pages)({state, actions})
	))
];
