'use strict';

const {
	section, h1, h2, h3, hr, header, i, ul, li, p, button, div, span,
	table, thead, tbody, tr, td, th, a, form, label, input, textarea
} = require('iblokz-snabbdom-helpers');

const marked = require('marked');
const moment = require('moment');

const roles = {
	user: 'Потребител',
	editor: 'Редактор',
	admin: 'Администратор'
};

module.exports = ({state, actions}) => div([
	section('.post', [
		button({
			on: {click: () => actions.router.go('admin/users/new')}
		}, [i('.fa.fa-plus'), 'Добави Потребител'])
	]),
	table('.crud', [
		thead([tr([
			th('[width="400"]', 'Име'),
			th('[width="300"]', 'Ел. Адрес'),
			th('[width="300"]', 'Роля'),
			th('[width="120"]', 'Действия')
		])]),
		tbody(state.users.list
			.sort((a, b) => new Date(a.start) < new Date(b.start) ? 1 : -1)
			.map(user => tr([
				td(user.fullName),
				td(user.email),
				td(roles[user.role]),
				td([
					// a(`.fa.fa-external-link[href="${user.url}"][target="_blank"]`),
					button('.fa.fa-pencil', {
						on: {click: () => actions.router.go(`admin/users/${user._id}`)}
					}),
					button('.fa.fa-trash', {
						on: {click: () => actions.users.delete(user._id, state.auth.token)}
					})
				])
			])))
	])
]);
