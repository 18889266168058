'use strict';

const {
	section, h1, h2, h3, h4, h5, hr, header, i, ul, li, p,
	table, thead, tbody, tr, td, th, a, div, strong, br,
	form, input, label, button, textarea
} = require('iblokz-snabbdom-helpers');

const marked = require('marked');
const agent = require('superagent');
const formUtil = require('../../util/form');
const {v1: uuidv1} = require('uuid');

const rightColumn = require('../right-column');

module.exports = ({state, actions}) => [
	section('.content', [
		section('.post', [
			h1('ТЕБЕ ПЕЕМ, БОГА СЛАВИМ')
		]),
		section('.post', [
			p(strong('[style="font-size: 1.2em"]', 'Форма за участие в инициативата')),
			p('[style="font-size: 0.8em"]', 'Повече информация за инициативата вижте по-долу'),
			state.campaignsTebePeemSent
			? p('[style="font-size: 1.4em"]', `Формата е изпратена. Благодарим за участието!`)
			: form('.edit', {
				on: {
					submit: ev => {
						ev.preventDefault();
						const data = formUtil.toData(ev.target);

						Object.keys(data)
							.reduce((req, field) =>
								field === 'mediafile' && data[field].files && data[field].files[0]
									? req.attach('mediafile', data[field].files[0])
									: field === 'audiofile' && data[field].files && data[field].files[0]
										? req.field(field, data[field].files[0].name)
										: req.field(field, data[field]),
								agent.post(`/api/media/tebe-peem`)
									.field('uuid', uuidv1()))
							.then(() => {
								console.log('successfully sent file');
								actions.set(['campaignsTebePeemSent'], true);
							});
						console.log(data);
					}
				}
			}, [
				div([
					label('Участник (име, email, тел. за връзка)'),
					input('[type="text"][name="contact"]', {})
				]),
				div([
					label('Име на песента'),
					input('[type="text"][name="name"]', {})
				]),
				div([
					label('Описание / История'),
					textarea('[name="description"]', {})
				]),
				div([
					label('Връзка към качен клип'),
					input('[type="text"][name="audiolink"]', {})
				]),
				div([
					label('Прикачи аудио файл (Максимум 10MB)'),
					input('[type="file"][name="audiofile"]', {})
				]),
				div([
					br(),
					button('[type="submit"]', 'Изпрати')
				])
			])
		]),
		section('.post', [
			p({props: {innerHTML: marked(`
***Съвместна инициатива на Дружество “РОДОЛБЕЦ” и програма “Хр. БОТЕВ” на Българското национално радио***

Дружеството за връзки с бесарабските и таврийските българи “Родолюбец” – гр. София през настоящата година чества своя 30-ти годишен юбилей. Наш партньор в отбелязването на тази годишнина е Българското национално радио.

По този повод и във връзка с наближаващите коледни празници Дружество “Родолюбец” и програма “Хр. Ботев” на БНР обявяват инициатива под надслов: “Тебе пеем, Бога славим”.

Целта е да бъдат издирени, записани и изпратени до нас в България коледарски песни от районите, населени компактно с българи в днешните държави Молдова, Украйна, Русия, Казахстан и др. Обръщаме се към всички наши сънародници от историко-културните области Бесарабия, Таврия, Крим, Приднестровието, от селищата около градовете Одеса, Николаев, Кировоград и пр., които се интересуват и поддържат жива връзката с родния си фолклор и традиции.

Издирените, записаните на подходящ звуконосител (аудио файл до 10MB, видео качено в youtube) и изпратени до нас песни ще бъдат съхранени във фонда на БНР, а освен това ще бъдат излъчвани и в различни негови предавания, както и по фолклорния канал Бинар.

На 10-ти януари 2021 г. предаването “От песен в песен” по програма “Хр. Ботев” на радиото ще посвети половин час от своето ефирно време за представяне на най-интересните, оригинални и качествени изпълнения.

Дружество “Родолюбец” също ще поощри всички, които се включат в инициативата със свои издания!

Нашата основна цел не е само да съхраним за фонда на БНР автентични песенни образци от фолклора на българите зад граница, но и да изградим своеобразен песенен мост между хората от диаспората и майка България!

Инициативата има отворен финал. Ориентировъчният краен срок за изпращането на записите на коледните песни е 06 януари 2021 г., когато по стар стил сънародниците ни от посочените райони празнуват своята Коледа и когато отново можем да чуем на живо по БНР техните песенни послания.

Скъпи наши сънародници от Бесарабия, Таврия, Крим и останалите области, очакваме Ви с отворени сърца, за да направим тази наша Коледа още по-истинска и звучна!

- Записите можете да качите с формата тук или изпратите на следните адреси: rodolubets@abv.bg, ...
- Видео файлове може да качите на youtube (като изберете опция unlisted) или друга платформа и изпратите линка пак с формата тук или по email.

**Дружество “РОДОЛЮБЕЦ”**
**Програма “Хр. БОТЕВ” на БНР**
			`)}})
		])
	]),
	rightColumn({state, actions})
];
