'use strict';

const path = require('path');

const {
	section, h1, h2, h3, hr, header, i, ul, li, p, button, div, span, img,
	table, thead, tbody, tr, td, th, a, form, label, input, textarea
} = require('iblokz-snabbdom-helpers');

const agent = require('superagent');

// comp
// const grid = require('../../../comp/grid');

// crud
// const list = require('./list');
// const edit = require('./edit');

const fileUtil = require('../../../../util/file');

const openDialog = cb => {
	let fileEl = document.createElement('input');
	fileEl.setAttribute('type', 'file');
	fileEl.addEventListener('change', ev => {
		console.log(ev.target.files, this);
		cb(
			ev.target.files
		);
	});
	fileEl.dispatchEvent(new MouseEvent('click', {
		view: window,
		bubbles: true,
		cancelable: true
	}));
};

module.exports = ({state, actions}) => [
	section('.content', [
		div([
			section('.post', [
				span(state.router.path.slice(2).length > 0 ? state.router.path.slice(2).map((chunk, index, slicedPath) => [
					' / ',
					slicedPath.length === index + 1
						? chunk
						: a(`[href="#/${state.router.path.slice(0, index + 3).join('/')}"]`, chunk)
				]).reduce((a, e) => [].concat(a, e), []) : '/'),
				ul('.right', [
					li(button('.fa.fa-th', {
						class: {active: state.admin.content.listType === 0},
						on: {click: () => actions.set(['admin', 'content', 'listType'], 0)}
					})),
					li(button('.fa.fa-th-list', {
						class: {active: state.admin.content.listType === 1},
						on: {click: () => actions.set(['admin', 'content', 'listType'], 1)}
					})),
					li(button('.fa.fa-upload[alt="Качи нов файл"]', {
						on: {
							click: ev => openDialog(files =>
									agent.put(path.resolve('/api/content/', state.router.pageId || '', files[0].name))
										.set(state.auth.token && {'x-access-token': state.auth.token} || {})
										.attach('theFile', files[0])
										.then(() => actions.content.list(state.content.query, state.router.pageId || ''))
							)
						}
					}))
				])
			]),
			section('.post.assets', [
				ul('.folders', [].concat(
						state.router.pageId ? {name: '..'} : [],
						state.content.list.filter(asset => asset.type === 'dir')
					).map(asset =>
					li(a(`[href="#/admin/content` + path.resolve(state.router.pageId || '', asset.name) + `"]`, [
						asset.name
					]))
				)),
				div('.files', state.admin.content.listType === 0
					? [].concat(
						state.content.list.filter(asset => asset.type === 'file').map(asset =>
							a(`.file[target="_blank"][href="/api/content` + path.resolve(state.router.pageId || '', asset.name) + `"]`, [
								div('.thumb', asset.name.match(/.(gif|jpeg|jpg|png|svg)/)
									? img(`[src=/api/content/` + path.resolve(state.router.pageId || '', asset.name) + `]`)
									: i('.fa' + (asset.name.match(/.pdf/) ? '.fa-file-pdf-o' : '.fa-file-o'))
								),
								div('.name', asset.name)
							])
						)
					) : table('.crud', [
						thead(tr([
							th('[width="40"]'),
							th('[width="80%"]', 'File'),
							th('Size'),
							th('Link'),
							th('Actions')
						])),
						tbody(state.content.list.filter(asset => asset.type === 'file').map(asset =>
							tr([
								td(asset.name.match(/.(gif|jpeg|jpg|png|svg)/)
									? img(`[src=/api/content/` + path.resolve(state.router.pageId || '', asset.name) + `]`)
									: i('.fa' + (asset.name.match(/.pdf/) ? '.fa-file-pdf-o' : '.fa-file-o'))),
								td(asset.name),
								td([(Number(asset.size) * 0.000001).toFixed(2), 'MB']),
								td(a(`.fa.fa-link[target="_blank"][href="/api/content` + path.resolve(state.router.pageId || '', asset.name) + `"]`)),
								td([
									button('.fa.fa-trash', {
										on: {click: () => window.confirm('Искате ли да изтриете файла?') && actions.content.delete(
											path.resolve(state.router.pageId || '', asset.name), state.auth.token)}
									})
								])
							])
						))
					])
				)
			])
		])
	])
];
