'use strict';

// dom
const {
	h1, a, div, header, p,
	section, button, span, ul, li
} = require('iblokz-snabbdom-helpers');

module.exports = ({state, actions, i18n}) => ul('.breadcrumb', [].concat(
	state.router.path.map((page, index, arr) =>
		li({
			style: {
				'font-size': `${
					((index === arr.length - 1) ? 1.2 : 1) * (page.length > 8 ? 0.8 : 1.2)
				}em`
			}
		}, ((arr.length - 1) === index ? span : a)(
			`[href="#/${arr.slice(0, index + 1).join('/')}"]`,
			i18n.pages[page] || i18n[page] || (
				index === 2 && arr[1] !== 'content' && arr.length === 3 && i18n.edit
			) || page
		))
	)
));
