
const {video, source} = require('iblokz-snabbdom-helpers');

let videoJs = require('video.js');
require('videojs-youtube');

// application/x-mpegURL

module.exports = ({src, type = 'video/youtube'}) => video({hook: {
	insert: ({elm}) => {
		let vid = videoJs(elm, {
			autoplay: false,
			controls: true,
			techOrder: ['youtube', 'html5']
		});
		vid.addClass('video-js');
		vid.addClass('vjs-default-skin');
	}}}, [
		source({
			hook: {
				update: ({elm}) => {
					// let vid = videoJs(elm.parentNode);
					// // console.log(
					// // 	elm.getAttribute('src'),
					// // 	vid.currentSrc()
					// // );
					// if (vid.currentSrc() !== elm.getAttribute('src')) {
					// 	vid.src({
					// 		src: elm.getAttribute('src'),
					// 		type: elm.getAttribute('type')
					// 	});
					// 	// vid.play();
					// }
				}
			},
			attrs: {
				src,
				type
			}})
	]);
