'use strict';

const {obj, fn} = require('iblokz-data');

const getParent = (el, tagName) => ([].concat(tagName).indexOf(el.tagName) === -1)
	? getParent(el.parentNode, tagName) : el;

const getElIndex = el => fn.pipe(
	() => Array.from(el.parentNode.children).indexOf(el),
	index => (console.log(index, el, Array.from(el.parentNode.children)), index),
	index => el.tagName === 'LI'
		? getElIndex(el.parentNode) + index + 1
		: (console.log(Array.from(el.parentNode.children).slice(0, index + 1)),
			Array.from(el.parentNode.children).slice(0, index + (el.tagName === 'UL' ? 0 : 1))
				.reduce((arr, item) => [].concat(arr, ['UL', 'OL'].indexOf(item.tagName) > -1
					? Array.from(item.children) : [item]), [])
				.map(item => (console.log(item), item))
				.length - 1
			)
)();

const getRangePoint = (el, offset) => ((console.log(el, offset)),
	(el.nodeType === 3 || el.childNodes.length === 0)
		? ({el, offset: (el.textContent.length < offset) ? el.textContent.length : offset})
		: Array.from(el.childNodes).reduce(
			(rp, child) => (rp.el !== el)
				? rp
				: (child.textContent.length >= rp.offset)
					? getRangePoint(child, rp.offset)
					: {el, offset: rp.offset - child.textContent.length},
			{el, offset: (el.tagName === 'LI' && el.parentNode.children.length === 1)
				? (el.textContent.length < offset) ? el.textContent.length : offset
				: offset
			}
		));

const trim = s => s.trim();
const toUpper = s => s.toUpperCase();

const get = (el, q = 'p, li, div') => {
	let range = window.getSelection().getRangeAt(0);
	console.log(range);
	// start
	let startLi = (q.split(',').map(trim).map(toUpper).indexOf(range.startContainer.tagName) > -1)
		? range.startContainer : getParent(range.startContainer, q.split(',').map(trim).map(toUpper));
	let startColRange = document.createRange();
	startColRange.setStart(startLi, 0);
	startColRange.setEnd(range.startContainer, range.startOffset);
	// end
	let endLi = (q.split(',').map(trim).map(toUpper).indexOf(range.endContainer.tagName) > -1)
		? range.endContainer : getParent(range.endContainer, q.split(',').map(trim).map(toUpper));
	let endColRange = document.createRange();
	endColRange.setStart(endLi, 0);
	endColRange.setEnd(range.endContainer, range.endOffset);
	console.log({
		start: {
			row: getElIndex(startLi),
			col: startColRange.toString().replace('\n', '').length
		},
		end: {
			row: getElIndex(endLi),
			col: endColRange.toString().replace('\n', '').length
		}
	});
	return {
		start: {
			row: getElIndex(startLi),
			col: startColRange.toString().replace('\n', '\n\n ').length
		},
		end: {
			row: getElIndex(endLi),
			col: endColRange.toString().replace('\n', '\n\n ').length
		}
	};
};

const set = (el, pos, q = 'p, li, div') => {
	console.log(pos, Array.from(el.querySelectorAll(q)), Array.from(el.querySelectorAll(q))[pos.start.row]);
	const startLi = Array.from(el.querySelectorAll(q))[pos.start.row];
	const endLi = Array.from(el.querySelectorAll(q))[pos.end.row];
	if (!startLi) return;
	const startRp = getRangePoint(startLi, pos.start.col);
	const endRp = getRangePoint(endLi, pos.end.col);
	console.log(startRp, endRp);
	let range = document.createRange();
	try {
		range.setStart(startRp.el, startRp.offset);
		range.setEnd(endRp.el, endRp.offset);
	} catch (e) {
		console.log(e);
		range.setStart(startRp.el, 0);
		range.setEnd(endRp.el, 0);
	}
	const sel = window.getSelection();
	sel.removeAllRanges();
	sel.addRange(range);
};

const indent = (el, direction = 'right', q = 'p, li, div') => {
	let range = window.getSelection().getRangeAt(0);
	let startLi = (q.split(',').map(trim).map(toUpper).indexOf(range.startContainer.tagName) > -1)
		? range.startContainer : getParent(range.startContainer, q.toUpperCase());
	let startIndex = getElIndex(startLi);
	let endLi = (q.split(',').map(trim).map(toUpper).indexOf(range.endContainer.tagName) > -1)
		? range.endContainer : getParent(range.endContainer, q.toUpperCase());
	let endIndex = getElIndex(endLi);
	if (startIndex === endIndex && direction === 'right') {
		document.execCommand('insertHTML', false, '&#009');
	} else {
		Array.from(startLi.parentNode.children)
			.filter((li, i) => i >= startIndex && i <= endIndex)
			.forEach(li => {
				if (direction === 'right') {
					let tabSpan = document.createElement('span');
					tabSpan.innerHTML = '\t';
					li.prepend(tabSpan);
				} else {
					console.log(`>${li.children[0].innerHTML}<`);
					Array.from(li.children).forEach(spEl => spEl.innerHTML === '' && spEl.remove());
					li.children[0].innerHTML = li.children[0].innerHTML.replace(/^\t/, '');
				}
			});
	}
};

module.exports = {
	get,
	set,
	indent
};
